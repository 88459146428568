<template>
  <Layout id="layouttttttttttt">

    <div class="card mt-2">
      <div class="card-body">

        <div style=" width: 50%;">
          <div >
            <h1><i class="mdi mdi-select-compare"
                                        style="transform: scale(1.7);"></i> Bank Reconciliation</h1>
            <label style="font-weight: 350;"><i class="mdi mdi-information"></i> This page contains the list of invoices to be matched with bank statement
            <br>To carry out the reconciliation please choose the RIB , the invoice and the transaction </label>
          </div>



          <form @submit.prevent="preventsubmit" class="mt-2">
            <div class="form-inline align-items-center">
            
              
              <label class="mr-3">RIB :</label>
              <multiselect placeholder="Select RIB" v-model="RIB" id="input-rib" :options="selectedRibs" @input="getAccountActivity()" style="width: 40%"  />

            
          </div>
            <!-- <b-form-group  class="text-left"
            label="Please select Transaction Type for results filter :" label-for="Inovice">
            <multiselect v-model="type" :options="InoviceType" id="Inovice" />
          </b-form-group> -->

            <!-- <label class="text-left" for="input-1">Search by amount :</label>
          <div class="d-flex align-items-center">

            <b-form-input type="search" id="input-1" v-model="text" 
              placeholder="Enter the amount" @keyup.enter="search()"></b-form-input>
            <b-button class="ml-2" size="lg" variant="primary" @click="search()"><i
                class="mdi mdi-magnify"></i></b-button>
            <b-button style="" class="ml-2" size="lg" variant="primary" @click="unfilter()"><i
                class="mdi mdi-undo-variant"></i></b-button>
          </div> -->
          </form>

          <!-- <table style="width: 100%">
            <tr>
              <td>
                <h5> Value Date Filter</h5>
              </td>
              <td>
                <h5>Date of Operation Filter</h5>
              </td>
            </tr>
            <tr>
              <td>
                <label class="text-left" for="example-datepicker1">From :</label>
                <b-form-datepicker style="width: 99%" id="example-datepicker1" v-model="fromDate"
                  class="mb-2">from</b-form-datepicker>
                <label class="text-left" for="example-datepicker2">To :</label>
                <b-form-datepicker style="width: 99%"  id="example-datepicker2" v-model="toDate"
                  class="mb-2">from</b-form-datepicker>

                <b-button class="ml-2" size="lg" variant="primary" @click="valueFilter()"><i
                    class="mdi mdi-filter-menu"></i></b-button>
                <b-button style="" class="ml-2" size="lg" variant="primary" @click="unfilter()"><i
                    class="mdi mdi-undo-variant"></i></b-button>
              </td>
              <td>
                <label class="text-left" for="example-datepicker3">From :</label>
                <b-form-datepicker style="width: 90%" id="example-datepicker3" v-model="fromDate1"
                  class="mb-2"></b-form-datepicker>
                <label class="text-left" for="example-datepicker4">To :</label>
                <b-form-datepicker style="width: 90%" id="example-datepicker4" v-model="toDate1"
                  class="mb-2">from</b-form-datepicker>

                <b-button class="ml-2" size="lg" variant="primary" @click="transactionFilter()"><i
                    class="mdi mdi-filter-menu"></i></b-button>
                <b-button style="" class="ml-2" size="lg" variant="primary" @click="unfilter()"><i
                    class="mdi mdi-undo-variant"></i></b-button>
              </td>
            </tr>
          </table> -->
          <div style="margin-left: auto; margin-right: auto; width: 60%;">
            <div class="mt-3">
              
            </div>
          </div>


        </div>


      </div>

    </div>
    
    <b-container fluid v-if="(this.RIB != null && this.RIB != '') && this.AccountActivity.activity.length > 0">
      

      <b-row>
        <b-col md="6">
          <b-pagination v-model="currentPage1" :total-rows="InvoicesList.length" :per-page="perPage1"
            aria-controls="smallTable1" class="w-100"></b-pagination>
            <label>Please select one Invoice :</label>
          <b-table ref="table2" id="smallTable1" class="smallTable1" :items="InvoicesList" :fields="InvoiceFields" selectable
            select-mode="single" :per-page="perPage1" :current-page="currentPage1" outlined sortable
            @row-selected="onRowinvoiceSelected($event)">
          </b-table>
          <b-pagination v-model="currentPage1" :total-rows="InvoicesList.length" :per-page="perPage1"
            aria-controls="smallTable1" class="w-100"></b-pagination>
        </b-col>

        <b-col md="6">
          <b-pagination v-model="currentPage2" :total-rows="AccountActivity.activity.length" :per-page="perPage2"
            aria-controls="smallTable2" class="w-100"></b-pagination>
            <label>Please select one Bank Transaction :</label>
          <b-table ref="table1" id="smallTable2" class="smallTable2" :items="AccountActivity.activity" :fields="AccountActivityFields"
            selectable select-mode="single" :per-page="perPage2" :current-page="currentPage2" outlined
            @row-selected="onRowTransactionSelected($event)">
          </b-table>
          <b-pagination v-model="currentPage2" :total-rows="AccountActivity.activity.length" :per-page="perPage2"
            aria-controls="smallTable2" class="w-100"></b-pagination>
        </b-col>
      </b-row>





    </b-container>
     <b-container v-else-if="(this.RIB != null && this.RIB != '') && this.AccountActivity.activity.length == 0">
      
      <h2 class="text-center">this RIB has no bank transactions</h2>
    </b-container> 
    <b-container v-else>
      <h2 class="text-center">Please Select a RIB to proceed to bank reconciliation</h2>
    </b-container>
  </Layout>
</template>
<style >
h1 {
    font-size: 28px;
    margin-bottom: 20px;
}
.btn-lg {
  line-height: 0.7 !important;
}

.smallTable1,
.smallTable2 {
  width: 100%;
  /* Ensure the table is not wider than its container */
  table-layout: fixed;
  /* This can help with handling overflow content */
}

.smallTable2 tbody td:nth-child(3),
.smallTable2 tbody th:nth-child(3) {
  overflow-wrap: break-word;

  /* For better compatibility with various browsers */
  max-width: 0;
}

.smallTable2 tbody td:nth-child(7),
.smallTable2 tbody th:nth-child(7) {
  overflow-wrap: break-word;

  /* For better compatibility with various browsers */
  max-width: 0;
}

.smallTable2 tbody td:nth-child(8),
.smallTable2 tbody th:nth-child(8) {
  overflow-wrap: break-word;

  /* For better compatibility with various browsers */
  max-width: 0;
}

.pagination-width {
  width: 100%;
  /* or any other value */
}
</style>
<script>

import Layout from "./layouts/main";
import axios, { all } from "axios";
import swal2 from "sweetalert2"
import swal1 from "sweetalert";
import Multiselect from "vue-multiselect";

/**
 * Invoice component
 */
export default {
  methods: {
  },
  data() {
    return {
      selectedRibs: [],
      RIB: "",
      falidSimplification: true,
      fromDate: null,
      toDate: null,
      fromDate1: null,
      toDate1: null,

      AccountActivityFields: [
       
      ],
      AccountActivity: {
        activity: null,
      },
      color: "",
      InvoiceFields: [
        "Invoice Date",
        "Invoice Number",
        "Customer Name",
        "Supplier Name",
        "Invoice Status",
        "Total all-inclusive",
        "Total",
      ],
      InvoicesList: [],
      bool: false,
      currentPage1: 1,
      perPage1: 8,
      currentPage2: 1,
      perPage2: 8,

      bankName: "",
      type: "",
      invoiceObject: [],
      transactionObject: [],
      BankNames: [],
      RIB: "",
      InoviceType: ["Credit", "Debit"],
      typeT: "",
      reconciliationCount: 0,
      reconciliation: {
        count: 0,
      },
      count: 0,

    };
  },
  methods: {
    getribs() {
      axios.get(`https://finex.4help.tn/api/v1/getribss/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { res.data.map(obj => this.selectedRibs.push(obj.RIB)) })
    },
    getCount() {
      axios.get("https://finex.4help.tn/api/v1/getReconciliationsCount").then((res) => {
        this.reconciliation = res.data;
        this.count = this.reconciliation.count;

      })
    },
    async onRowTransactionSelected(event) {
      // console.log("this is transaction event[0]");
      this.color = "red"

      this.transactionObject = event;
      //console.log(this.transactionObject);

      if (this.invoiceObject.length == 0) {
        console.log("this.invoiceObject is not defined");

      } else {
        if (this.type == "" || this.type == null) {
          console.log(" this.RIB is",this.RIB);
          console.log(" this.invoiceObject is",this.invoiceObject[0]);
          console.log(" this.transactionObject is",this.transactionObject[0]);
          axios.post("https://finex.4help.tn/api/v1/addReconciliation", {
            "Societe": localStorage.getItem('societe').slice(1, -1),
            "RIB": this.RIB,
            "Invoice": this.invoiceObject[0],
            "Transaction": this.transactionObject[0],
          })
            .then(async (res) => {
              swal1({
                title: "Success!",
                text: "Reconciliation added successfully",
                icon: "success",
              })
              this.AccountActivity=await axios.patch("https://finex.4help.tn/api/v1/deleteActivity", {
                "RIB": this.RIB,
                "activity": this.transactionObject[0],
              });
             axios.delete(`https://finex.4help.tn/api/v1/deleteFactureVente/${this.invoiceObject[0]["Invoice Number"]}`);
             axios.delete(`https://finex.4help.tn/api/v1/deletePurchaseInvoice/${this.invoiceObject[0]["Invoice Number"]}`);
             this.invoiceObject = [];
              this.transactionObject = [];
              //this.unfilter();
            }).catch((err) => {
              this.$refs.table1.clearSelected();
              this.$refs.table2.clearSelected();
              //this.unfilter();
              this.invoiceObject = [];
              this.transactionObject = [];
              //this.getCount();
                swal1({
                  title: "Error!",
                  text: err.response.data.message,
                  icon: "error",
                })
            });
        } 
        // else if (this.type == "Debit") {
        //   axios.post("https://finex.4help.tn/api/v1/addReconciliation", {
        //     "RIB": this.RIB,
        //     "Invoice": this.invoiceObject[0],
        //     "Transaction": this.transactionObject[0],
        //   }).then((res) => {
        //     swal1({
        //       title: "Success!",
        //       text: "Reconciliation added successfully",
        //     })
        //   })
        //   await axios.patch("https://finex.4help.tn/api/v1/deleteActivity", {
        //     "RIB": this.RIB,
        //     "activity": this.transactionObject[0],
        //   })
        //   axios.delete(`https://finex.4help.tn/api/v1/deleteFactureVente/${this.invoiceObject[0]["Invoice Number"]}`);
        //   this.invoiceObject = [];
        //   this.transactionObject = [];
        //   this.getCount();
        //   this.unfilter();
        // } else if (this.type == "Credit") {
        //   axios.post("https://finex.4help.tn/api/v1/addReconciliation", {
        //     "RIB": this.RIB,
        //     "Invoice": this.invoiceObject[0],
        //   }).then((res) => {
        //     swal1({
        //       title: "Success!",
        //       text: "Reconciliation added successfully",
        //     })
        //   })
        //   await axios.patch("https://finex.4help.tn/api/v1/deleteActivity", {
        //     "RIB": this.RIB,
        //     "activity": this.transactionObject[0],
        //   })
        //   axios.delete(`https://finex.4help.tn/api/v1/deletePurchaseInvoice/${this.invoiceObject[0]["Invoice Number"]}`);
        //   this.invoiceObject = [];
        //   this.transactionObject = [];
        //   this.getCount();
        //   this.unfilter();
        // }
      }

    },
    async onRowinvoiceSelected(event) {
      this.RIB = this.AccountActivity.RIB;
      this.invoiceObject =event;
      //console.log(this.invoiceObject);

      if (this.transactionObject.length == 0) {
        console.log("this.transactionObject is not defined");
      } else {

        if (this.type == "" || this.type == null) {
          console.log(" this.RIB is",this.RIB);
          console.log(" this.invoiceObject is",this.invoiceObject[0]);
          console.log(" this.transactionObject is",this.transactionObject[0]);
          axios.post("https://finex.4help.tn/api/v1/addReconciliation", {
            "Societe": localStorage.getItem('societe').slice(1, -1),
            "RIB": this.RIB,
            "Invoice": this.invoiceObject[0],
            "Transaction": this.transactionObject[0],
          })
            .then(async (res) => {
              swal1({
                title: "Success!",
                text: "Reconciliation added successfully",
                icon: "success",
              })
              this.AccountActivity= await axios.patch("https://finex.4help.tn/api/v1/deleteActivity", {
                "RIB": this.RIB,
                "activity": this.transactionObject[0],
              });
              
              axios.delete(`https://finex.4help.tn/api/v1/deleteFactureVente/${this.invoiceObject[0]["Invoice Number"]}`);
              axios.delete(`https://finex.4help.tn/api/v1/deletePurchaseInvoice/${this.invoiceObject[0]["Invoice Number"]}`);
              this.invoiceObject = [];
              this.transactionObject = [];
              //this.unfilter();
            }).catch((err) => {
              this.$refs.table1.clearSelected();
              this.$refs.table2.clearSelected();
              //this.unfilter();
              this.invoiceObject = [];
              this.transactionObject = [];
             // this.getCount();
                swal1({
                  title: "Error!",
                  text: err.response.data.message,
                  icon: "error",
                })
            });
          

          //alert("this is transactionObject[0].InvoiceNumber"+this.invoiceObject[0]["Invoice Number"]);
          
        } 
        // else if (this.type == "Debit") {
        //   axios.post("https://finex.4help.tn/api/v1/addReconciliation", {
        //     "RIB": this.RIB,
        //     "Invoice": this.invoiceObject[0],
        //     "Transaction": this.transactionObject[0],
        //   }).then((res) => {
        //     swal1({
        //       title: "Success!",
        //       text: "Reconciliation added successfully",
        //     })
        //   })
        //   await axios.patch("https://finex.4help.tn/api/v1/deleteActivity", {
        //     "RIB": this.RIB,
        //     "activity": this.transactionObject[0],
        //   })
        //   axios.delete(`https://finex.4help.tn/api/v1/deleteFactureVente/${this.invoiceObject[0]["Invoice Number"]}`);
        //   this.invoiceObject = [];
        //   this.transactionObject = [];
        //   this.getCount();
        //   this.unfilter();
        // } else if (this.type == "Credit") {
        //   axios.post("https://finex.4help.tn/api/v1/addReconciliation", {
        //     "RIB": this.RIB,
        //     "Invoice": this.invoiceObject[0],
        //   }).then((res) => {
        //     swal1({
        //       title: "Success!",
        //       text: "Reconciliation added successfully",
        //     })
        //   })
        //   await axios.patch("https://finex.4help.tn/api/v1/deleteActivity", {
        //     "RIB": this.RIB,
        //     "activity": this.transactionObject[0],
        //   })
        //   axios.delete(`https://finex.4help.tn/api/v1/deletePurchaseInvoice/${this.invoiceObject[0]["Invoice Number"]}`);
        //   this.invoiceObject = [];
        //   this.transactionObject = [];
        //   this.getCount();
        //   this.unfilter();
        // }
      }




    },

    // async valueFilter() {
    //   this.InvoiceFields = ["Invoice Date", "Invoice Number", "Customer Name", "Supplier Name", "Invoice Status", "Total", "Total all-inclusive",];
    //   this.InvoicesList = await axios.get(`https://finex.4help.tn/api/v1/getsaleinvoice/${localStorage.getItem('societe').slice(1, -1)}`)
    //     .then((res) => { return res.data; });

    //   this.InvoicesList = this.InvoicesList.concat(
    //     await axios.get(`https://finex.4help.tn/api/v1/${localStorage.getItem('societe').slice(1, -1)}`).then((res) => { return res.data; })
    //   );
    //   if (this.fromDate == null && this.toDate == null) {
    //     swal1({
    //       title: "Error!",
    //       text: "Please select both dates",
    //     })
    //   } else if (this.fromDate > this.toDate) {
    //     swal1({
    //       title: "Error!",
    //       text: "Please select valid dates",
    //     })
    //   } else if (this.fromDate != null && this.toDate == null) {

    //     for (let i = 0; i < this.InvoicesList.length; i++) {
    //       if ((this.InvoicesList[i]["Invoice Date"] < this.fromDate)) {
    //         this.InvoicesList.splice(i, 1);
    //         i--;
    //       }

    //     }
    //     for (let i = 0; i < this.AccountActivity.activity.length; i++) {
    //       if (this.AccountActivity.activity[i]["Date Valeur"] < this.fromDate) {
    //         this.AccountActivity.activity.splice(i, 1);
    //         i--;
    //       }
    //     }
    //   } else if (this.toDate != null && this.fromDate == null) {
    //     for (let i = 0; i < this.InvoicesList.length; i++) {
    //       if (this.InvoicesList[i]["Invoice Date"] > this.toDate) {
    //         this.InvoicesList.splice(i, 1);
    //         i--;
    //       }

    //     }
    //     for (let i = 0; i < this.AccountActivity.activity.length; i++) {
    //       if (this.AccountActivity.activity[i]["Date Valeur"] > this.toDate) {
    //         this.AccountActivity.activity.splice(i, 1);
    //         i--;
    //       }

    //     }
    //   } else {
    //     for (let i = 0; i < this.InvoicesList.length; i++) {
    //       if (this.InvoicesList[i]["Invoice Date"] < this.fromDate || this.InvoicesList[i]["Invoice Date"] > this.toDate) {
    //         this.InvoicesList.splice(i, 1);
    //         i--;
    //       }

    //     }
    //     for (let i = 0; i < this.AccountActivity.activity.length; i++) {
    //       if (this.AccountActivity.activity[i]["Date Valeur"] < this.fromDate || this.AccountActivity.activity[i]["Date"] > this.toDate) {
    //         this.AccountActivity.activity.splice(i, 1);
    //         i--;
    //       }
    //     }
    //   }




    // },
    // async transactionFilter() {
    //   this.InvoiceFields = ["Invoice Date", "Invoice Number", "Customer Name", "Supplier Name", "Invoice Status", "Total", "Total all-inclusive",];
    //   this.InvoicesList = await axios.get(`https://finex.4help.tn/api/v1/getsaleinvoice/${localStorage.getItem('societe').slice(1,-1)}`)
    //     .then((res) => { return res.data; });

    //   this.InvoicesList = this.InvoicesList.concat(
    //     await axios.get(`https://finex.4help.tn/api/v1/getpurchaseinvoice/${localStorage.getItem('societe').slice(1,-1)}`).then((res) => { return res.data; })
    //   );
    //   if (this.fromDate1 == null && this.toDate1 == null) {
    //     swal1({
    //       title: "Error!",
    //       text: "Please select both dates",
    //     })
    //   } else if (this.fromDate1 > this.toDate1) {
    //     swal1({
    //       title: "Error!",
    //       text: "Please select valid dates",
    //     })
    //   } else if (this.fromDate1 != null && this.toDate1 == null) {

    //     for (let i = 0; i < this.InvoicesList.length; i++) {
    //       if ((this.InvoicesList[i]["Invoice Date"] < this.fromDate1)) {
    //         this.InvoicesList.splice(i, 1);
    //         i--;
    //       }

    //     }
    //     for (let i = 0; i < this.AccountActivity.activity.length; i++) {
    //       if (this.AccountActivity.activity[i]["Date Operation"] < this.fromDate1) {
    //         this.AccountActivity.activity.splice(i, 1);
    //         i--;
    //       }
    //     }
    //   } else if (this.toDate1 != null && this.fromDate1 == null) {
    //     for (let i = 0; i < this.InvoicesList.length; i++) {
    //       if (this.InvoicesList[i]["Invoice Date"] > this.toDate1) {
    //         this.InvoicesList.splice(i, 1);
    //         i--;
    //       }

    //     }
    //     for (let i = 0; i < this.AccountActivity.activity.length; i++) {
    //       if (this.AccountActivity.activity[i]["Date Operation"] > this.toDate1) {
    //         this.AccountActivity.activity.splice(i, 1);
    //         i--;
    //       }

    //     }
    //   } else {
    //     for (let i = 0; i < this.InvoicesList.length; i++) {
    //       if (this.InvoicesList[i]["Invoice Date"] < this.fromDate1 || this.InvoicesList[i]["Invoice Date"] > this.toDate1) {
    //         this.InvoicesList.splice(i, 1);
    //         i--;
    //       }

    //     }
    //     for (let i = 0; i < this.AccountActivity.activity.length; i++) {
    //       if (this.AccountActivity.activity[i]["Date Operation"] < this.fromDate1 || this.AccountActivity.activity[i]["Date"] > this.toDate1) {
    //         this.AccountActivity.activity.splice(i, 1);
    //         i--;
    //       }
    //     }
    //   }




    // },
    async unfilter() {
      try {
        this.toDate = null;
      this.fromDate = null;
      this.toDate1 = null;
      this.fromDate1 = null;
      this.type = null;
      this.InvoiceFields = ["Invoice Date", "Customer Name", "Supplier Name", "Total", "Total all-inclusive",];
      console.log("///////////////////////////////")
       await axios.get(`https://finex.4help.tn/api/v1/getsaleinvoice/${localStorage.getItem('societe').slice(1, -1)}`)
        .then((res) => { this.InvoicesList = res.data; });
     
      
      console.log("///////////////////////////////")
      await axios.get(`https://finex.4help.tn/api/v1/getpurchaseinvoice/${localStorage.getItem('societe').slice(1, -1)}`)
        .then((res) => {console.log(res.data); this.InvoicesList = this.InvoicesList.concat(res.data); });
      console.log(this.InvoicesList)

      //this.AccountActivity.activity = [];
      } catch (e) {
        console.log(e)
      }

     },
    // async search() {
    //   this.InvoiceFields = ["Invoice Date", "Invoice Number", "Customer Name", "Supplier Name", "Invoice Status", "Total", "Total all-inclusive",];
    //   this.InvoicesList = await axios.get(`https://finex.4help.tn/api/v1/getsaleinvoice/${localStorage.getItem('societe').slice(1, -1)}`)
    //     .then((res) => { return res.data; });

    //   this.InvoicesList = this.InvoicesList.concat(
    //     await axios.get(`https://finex.4help.tn/api/v1/getpurchaseinvoice/${localStorage.getItem('societe').slice(1, -1)}`).then((res) => { return res.data; })
    //   );
    //   if (isNaN(+this.text)) {
    //     swal1({
    //       title: "Error!",
    //       text: "Please enter a valid number",
    //     })
    //   } else if (+this.text < 0) {
    //     swal1({
    //       title: "Error!",
    //       text: "Please enter a positive number",
    //     })
    //   } else {
    //     if (this.type == "Debit") {
    //       this.AccountActivityFields = [
    //         "Code",
    //         "Reference",
    //         "Date",
    //         "Value Date",
    //         "Description",
    //         "Debit",
    //         "Credit",
    //       ];
    //       this.AccountActivity.activity = this.AccountActivity.activity.filter((item) => item["Montant"] == +this.text);
    //       this.InvoiceFields = ["Invoice Date", "Invoice Number", "Customer Name", "Supplier Name", "Invoice Status", "Currency", "Total"];
    //       this.InvoicesList = this.InvoicesList.filter((item) => item["Total"] == +this.text);
    //     } else if (this.type == "Credit") {
    //       this.AccountActivityFields = [
    //         "Code",
    //         "Reference",
    //         "Date",
    //         "Value Date",
    //         "Description",
    //         "Debit",
    //         "Credit",
    //       ];
    //       this.AccountActivity.activity = this.AccountActivity.activity.filter((item) => item["Montant"] == -this.text);
    //       this.InvoiceFields = ["Invoice Date", "Invoice Number", "Customer Name", "Supplier Name", "Invoice Status", "Currency", "Total all-inclusive"];
    //       this.InvoicesList = this.InvoicesList.filter((item) => item["Total all-inclusive"] == +this.text);
    //     } else {
    //       this.AccountActivityFields = [
    //         "Code",
    //         "Reference",
    //         "Date",
    //         "Value Date",
    //         "Description",
    //         "Debit",
    //         "Credit",
    //       ];
    //       this.InvoiceFields = ["Invoice Date", "Invoice Number", "Customer Name", "Supplier Name", "Invoice Status", "Currency", "Total", "Total all-inclusive"];
    //       await this.getAccountActivity()
    //       this.AccountActivity.activity = this.AccountActivity.activity.filter((item) => item["Montant"] == -this.text || item["Credit"] == +this.text);
    //       this.InvoicesList = this.InvoicesList.filter((item) => item["Total"] == +this.text || item["Total all-inclusive"] == +this.text);
    //       ;
    //     }


    //   }
    // },
    async getAccountActivity() {  
        
         await axios.get(`https://finex.4help.tn/api/v1/account-activity/${this.RIB}/${localStorage.getItem('societe').slice(1, -1)}`).then(res => { this.AccountActivity = res.data 
          this.AccountActivityFields = Object.keys(this.AccountActivity.activity[0]) ;
        this.AccountActivityFields.shift();
        this.AccountActivityFields.splice(3, 1);
        this.AccountActivityFields.splice(3, 1);})
        .catch(err => {
          console.log(err.code);
          return this.AccountActivity.activity = [];
        })
          
         console.log(this.AccountActivity.activity.length)
        
      
    },
    test(){
      console.log("m.k.mk.m.k.mk.mk..ml.ml.k....")
    }
  },

  async mounted() {




  },
  computed: {
    
  },

  created() {    
    this.unfilter();
    this.getribs();
    this.getCount();
  },
  components: {
    Layout,
    Multiselect,
  },

  watch: {
    'AccountActivity' : 'test'
    // async type() {

    //   if (this.type == "Debit") {
    //     this.typeT = "Debit"
    //     this.AccountActivityFields = [
    //     "Code",
    //     "Reference",
    //     "Date",
    //     "Value Date",
    //     "Description",        
    //     "Debit",
    //     "Credit",
    //   ];
    //     try {
    //       this.AccountActivity = await axios.get(`https://finex.4help.tn/api/v1/getactivity/${localStorage.getItem("societe").slice(1,-1)}`)
    //         .then(res => { return res.data[0] })
    //       for (let i = 0; i < this.AccountActivity.activity.length; i++) {
    //         if (this.AccountActivity.activity[i]["Debit"] == 0 && this.AccountActivity.activity[i]["Credit"] != 0) {
    //           this.AccountActivity.activity.splice(i, 1);
    //           i--;
    //         }

    //       }
    //       console.log(this.AccountActivity)
    //     } catch (e) {
    //       throw new Error('error in request : getAccountActivity')
    //     }



    //     try {
    //       this.InvoicesList = await axios
    //         .get(`https://finex.4help.tn/api/v1/getsaleinvoice/${localStorage.getItem("societe").slice(1,-1)}`)
    //         .then((res) => {
    //           return res.data;
    //         });
    //       console.log(this.listeFacture);
    //       this.InvoiceFields = ["Invoice Date", "Invoice Number", "Customer Name", "Invoice Status", "Currency", "Total",
    //       ];
    //     } catch (e) {
    //       throw new Error("error in watch type : Debit");
    //     }
    //   }
    //   if (this.type == "Credit") {
    //     this.typeT = "Credit"
    //     this.AccountActivityFields = [
    //     "Code",
    //     "Reference",
    //     "Date",
    //     "Value Date",
    //     "Description",        
    //     "Debit",
    //     "Credit",
    //   ];
    //     try {
    //       this.AccountActivity = await axios.get(`https://finex.4help.tn/api/v1/getactivity/${localStorage.getItem("societe").slice(1,-1)}`)
    //         .then(res => { return res.data[0] })
    //       for (let i = 0; i < this.AccountActivity.activity.length; i++) {
    //         if (this.AccountActivity.activity[i]["Credit"] == 0 && this.AccountActivity.activity[i]["Debit"] != 0) {
    //           this.AccountActivity.activity.splice(i, 1);
    //           i--;
    //         }
    //       }
    //     } catch (e) {
    //       throw new Error('error in request : getAccountActivity')
    //     }
    //     try {
    //       this.InvoicesList = await axios
    //         .get(`https://finex.4help.tn/api/v1/getpurchaseinvoice/${localStorage.getItem("societe").slice(1,-1)}`)
    //         .then((res) => { return res.data; });
    //       console.log(this.InvoicesList);
    //       this.InvoiceFields = ["Invoice Date", "Invoice Number", "Supplier Name", "Currency", "Total all-inclusive",];
    //     } catch (e) {
    //       throw new Error("error in watch type : pruchase");
    //     }
    //   }
    //   if (this.type == null) {
    //     this.typeT = ""
    //     this.AccountActivityFields = [
    //     "Code",
    //     "Reference",
    //     "Date",
    //     "Value Date",
    //     "Date Valeur",
    //     "Date Operation",
    //     "Libelle",
    //     "Description",
    //     "Montant",        
    //     "Debit",
    //     "Credit",
    //   ];
    //     this.AccountActivity = await axios.get(`https://finex.4help.tn/api/v1/account-activity/${this.RIB}/${localStorage.getItem('societe').slice(1,-1)}`)
    //       .then(res => { return res.data[0] })

    //     this.InvoiceFields = ["Invoice Date", "Invoice Number", "Customer Name", "Supplier Name", "Invoice Status", "Currency", "Total", "Total all-inclusive",];
    //     this.InvoicesList = await axios.get(`https://finex.4help.tn/api/v1/getsaleinvoice/${localStorage.getItem("societe").slice(1,-1)}`)
    //       .then((res) => { return res.data; });

    //     this.InvoicesList = this.InvoicesList.concat(
    //       await axios.get(`https://finex.4help.tn/api/v1/getpurchaseinvoice/${localStorage.getItem("societe").slice(1,-1)}`).then((res) => { return res.data; })
    //     );
    //   }


    // },
  },
};
</script>

